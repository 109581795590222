<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">出入库管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--  出入库管理-->
    <div style="width: 94%;margin: auto;background-color: #FFFFFF;min-height: 80vh">
      <div style="padding-left: 3%;padding-top: 0.5%;display: flex;width: 100%">
        <div v-if="GET_BDST === '1'">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 选择工区</el-tag>
          <el-select v-model="bdst" class="m-2" placeholder="请选择工区" clearable size="small" style="width: 65%"
                     @change="changeBdst()">
            <el-option
                v-for="item in bdsts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <div style="display: flex;margin-left: 5%">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 材料名称</el-tag>
          <el-input v-model="material_name" placeholder="请输入材料名称" clearable size="small" style="width: 65%"
                    @input="changeMaterial()"/>
        </div>
        <div style="margin-left: 5%">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 出入库</el-tag>
          <el-select v-model="ware_issue" class="m-2" placeholder="请选择出入库" clearable size="small"
                     style="width: 65%" @change="changeWareIssue()">
            <el-option
                v-for="item in ware_issues"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <!--  材料流水-->
      <div>
        <div style="width: 80%;height: 5vh;background-color: #b4cbf2;margin-top: 1%;display: flex;padding-left: 20%">
          <!--  入库数量-->
          <div style="display: flex;">
            <div style="width: 30%">
              <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/material_speed.png"
                   style="width: 85%;margin-top: 3%">
            </div>
            <div style="margin-right: 10%;width: 50%">
              <p style="color: #2b508f;font-size: 20px;font-weight: bolder;margin-top: 6%">{{ wareNum }}</p>
              <p style="color: #2b508f;font-size: 18px;font-weight: bolder;margin-top: -10%;margin-right: 3%">
                收料数量</p>
            </div>
          </div>
          <!--  出库数量-->
          <div style="display: flex;margin-left: 40%">
            <div style="width: 30%">
              <img src="https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/material_speed.png"
                   style="width: 85%;margin-top: 3%">
            </div>
            <div style="margin-right: 10%;width: 50%">
              <p style="color: #2b508f;font-size: 20px;font-weight: bolder;margin-top: 6%">{{ issueNum }}</p>
              <p style="color: #2b508f;font-size: 18px;font-weight: bolder;margin-top: -10%;margin-right: 3%">
                用料数量</p>
            </div>
          </div>
        </div>
        <!--  流水-->
        <el-scrollbar height="78vh">
          <div style="width: 100%">
            <div v-for="(item, index) in material_runwater" :key="index" style="margin-top: 2%">
              <!--入库-->
              <div v-if="item.flag === '0'">
                <div style="height: 5vh;background-color: #2d7ee9;">
                <span style="color: #FFFFFF;font-weight: bolder;line-height: 5vh;margin-left: 10%">所属工区: {{
                    item.bdst
                  }}</span>
                  <span style="color: #FFFFFF;font-weight: bolder;line-height: 5vh;margin-left: 30%">车牌号: {{
                      item.car
                    }}</span>
                  <span style="color: #FFFFFF;font-weight: bolder;line-height: 5vh;margin-left: 25%">入库时间: {{
                      item.time
                    }}</span>
                </div>
                <div
                    style="width: 99%;height: 7vh;background-color: #eaf4fd;display: flex;padding-left: 1%;padding-top: 1%;text-align: center"
                    @dblclick="removeWarehouse(item)">
                  <!--供应单位-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.materialSource }}
                    </div>
                    <div>
                      供应单位
                    </div>
                  </div>
                  <!--  材料信息-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.material_name }}
                    </div>
                    <div>
                      {{ item.material_model }}
                    </div>
                  </div>
                  <!--  计量单位-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.unit }}
                    </div>
                    <div>
                      计量单位
                    </div>
                  </div>
                  <!--收料量-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.wareIssueNum }}
                    </div>
                    <div>
                      收料量
                    </div>
                  </div>
                  <!--提交人-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.upUser }}
                    </div>
                    <div>
                      提交人
                    </div>
                  </div>
                </div>
              </div>
              <!--出库-->
              <div v-if="item.flag === '1'">
                <div style="height: 5vh;background-color: #30c15b;">
                <span style="color: #FFFFFF;font-weight: bolder;line-height: 5vh;margin-left: 10%">所属工区: {{
                    item.bdst
                  }}</span>
                  <span style="color: #FFFFFF;font-weight: bolder;line-height: 5vh;margin-left: 30%">车牌号: {{
                      item.car
                    }}</span>
                  <span style="color: #FFFFFF;font-weight: bolder;line-height: 5vh;margin-left: 25%">出库时间: {{
                      item.time
                    }}</span>
                </div>
                <div
                    style="width: 99%;height: 7vh;background-color: #ebf9e8;display: flex;padding-left: 1%;padding-top: 1%;text-align: center"
                @dblclick="removeIssue(item)">
                  <!--供应单位-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.materialSource }}
                    </div>
                    <div>
                      使用单位
                    </div>
                  </div>
                  <!--  材料信息-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.material_name }}
                    </div>
                    <div>
                      {{ item.material_model }}
                    </div>
                  </div>
                  <!--  计量单位-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.unit }}
                    </div>
                    <div>
                      计量单位
                    </div>
                  </div>
                  <!--收料量-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.wareIssueNum }}
                    </div>
                    <div>
                      出料量
                    </div>
                  </div>
                  <!--提交人-->
                  <div style="width: 20%;line-height: 3vh">
                    <div style="font-weight: bolder;font-size: 1.2rem">
                      {{ item.upUser }}
                    </div>
                    <div>
                      提交人
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import { ElMessage, ElMessageBox } from 'element-plus';

export default {
  name: "material_issue",
  components: {Header},
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  inject: ['reload'],
  data() {
    return {
      bdst: '',
      bdsts: [
        {
          value: '21',
          label: '2标一工区/5标六工区',
        },
        {
          value: '22',
          label: '2标二工区',
        },
        {
          value: '51',
          label: '5标一工区',
        },
        {
          value: '52',
          label: '5标二工区',
        },
        {
          value: '53',
          label: '5标三工区',
        },
        {
          value: '54',
          label: '5标四工区',
        },
        {
          value: '55',
          label: '5标五工区',
        },
        {
          value: '57',
          label: '5标七工区',
        }
      ],
      ware_issue: '',
      ware_issues: [
        {
          value: '0',
          label: '入库',
        },
        {
          value: '1',
          label: '出库',
        }
      ],
      material_name: '',
      material_runwater: [],
      wareNum: '',
      issueNum: ''
    }
  },
  created() {
    this.axios.post('/materialWarehouse/getWareIssuePc', (response) => {
      this.wareNum = response.obj[0];
      this.issueNum = response.obj[1];
      this.material_runwater = response.obj2;
    }, {
      bdst: this.bdst,
      material_name: this.material_name,
      ware_issue: this.ware_issue
    })
  },
  methods: {
    changeBdst() {
      this.axios.post('/materialWarehouse/getWareIssuePc', (response) => {
        this.wareNum = response.obj[0];
        this.issueNum = response.obj[1];
        this.material_runwater = response.obj2;
      }, {
        bdst: this.bdst,
        material_name: this.material_name,
        ware_issue: this.ware_issue
      })
    },
    changeMaterial() {
      this.axios.post('/materialWarehouse/getWareIssuePc', (response) => {
        this.wareNum = response.obj[0];
        this.issueNum = response.obj[1];
        this.material_runwater = response.obj2;
      }, {
        bdst: this.bdst,
        material_name: this.material_name,
        ware_issue: this.ware_issue
      })
    },
    changeWareIssue() {
      this.axios.post('/materialWarehouse/getWareIssuePc', (response) => {
        this.wareNum = response.obj[0];
        this.issueNum = response.obj[1];
        this.material_runwater = response.obj2;
      }, {
        bdst: this.bdst,
        material_name: this.material_name,
        ware_issue: this.ware_issue
      })
    },
    removeIssue(item){
      ElMessageBox.confirm(
          '是否确定删除此条记录?',
          '删除记录',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/materialIssue/removeIssueById', (response) => {
              if (response.obj){
                ElMessage({
                  type: 'success',
                  message: '删除成功',
                })
                this.reload();
              }
            },{
              material_id: item.material_id,
              material_num: item.wareIssueNum,
              id: item.id
            })

          })
          .catch(() => {
            // ElMessage({
            //   type: 'info',
            //   message: 'Delete canceled',
            // })
          })

    },
    removeWarehouse(item){
      ElMessageBox.confirm(
          '是否确定删除此条记录?',
          '删除记录',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            this.axios.post('/materialWarehouse/removeWareById', (response) => {
              if (response.obj){
                ElMessage({
                  type: 'success',
                  message: '删除成功',
                })
                this.reload();
              }
            },{
              material_id: item.material_id,
              material_num: item.wareIssueNum,
              id: item.id
            })

          })
          .catch(() => {
            // ElMessage({
            //   type: 'info',
            //   message: 'Delete canceled',
            // })
          })
    }

  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
</style>